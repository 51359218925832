<template>
  <div class="row_type" style="padding: 2rem 2rem 4rem 2rem;">
    <!--'가로형: row_type'-->
    <ul class="wzp_list_ul" style="padding: 0;">
      <li
        class="wzp_list_li click_bg_dt"
        style="flex-direction: column;"
        v-for="(item, index) in list"
        :key="index"
        @click="fnView(item)"
      >
        <div class="flex_area">
          <div class="box txt">
            <div class="party_ttl">
              <div class="party_tag">
                <div class="left">
                  <div
                    class="pt_tag logo"
                    style="--pt-txt-color: #fff;"
                    :style="{
                      '--pt-bg-color': $ConstCode.getCategoryByText(
                        item.category
                      ).color
                    }"
                  >
                    {{ $ConstCode.getCategoryByText(item.category).name }}
                  </div>
                </div>
              </div>
              <span style="font-weight: 500;">{{ item.titleKr }}</span>
            </div>
          </div>
          <div class="box img">
            <div class="thumb">
              <div class="inner">
                <img :src="item.thumbnail" :alt="item.titleKr" />
              </div>
            </div>
          </div>
        </div>
        <div class="comment_list p_0">
          <ul class="comment_list_ul p_0">
            <li class="comment_list_li p_0">
              <div class="top">
                <div class="main_star_heart">
                  <div class="rank">
                    <div class="stars" :class="getClassName(item.starRating)">
                      <!--'0.5점: n05', '1.0점: n10', ~ '5.0점: n50'-->
                      <ul>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                    <div class="rank_nb">
                      <span>{{ item.starRating }}</span>
                    </div>
                  </div>
                  <div class="heart_area">
                    <div class="heart_nb">{{ item.recommendCount }}</div>
                    <label class="checkbox">
                      <input type="checkbox" v-model="item.recommendMine" />
                      <span
                        class="label"
                        @click.prevent.stop="fnUpdateCommentRecommend(item)"
                      >
                      </span>
                    </label>
                  </div>
                </div>
                <div class="comment_list_ttl">
                  <div class="left">
                    <div class="name">{{ item.name }}</div>
                    <div class="color" v-show="item.critic">Critic</div>
                  </div>
                </div>
              </div>
              <div class="btm">
                <span
                  ><!--붙여쓰기-->{{ item.comment
                  }}<!--붙여쓰기--></span
                >
              </div>
              <!--                  TODO:// 마이페이지 평점 관리에서는 데이트가 안보여서 임시로 넣어두었습니다. -->
              <div class="comment_list_ttl" v-if="isDate">
                <div class="left">
                  <div class="date">
                    {{ $dayjs(item.wrtime).format('YYYY.MM.DD')
                    }}<span
                      class="color_error"
                      v-show="$dayjs().diff($dayjs(item.wrtime), 'hours') < 24"
                    >
                      N</span
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'party-score-list',
  props: {
    isDate: {
      type: Boolean,
      default: true
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
