import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData'])
  })
  const router = useRouter()
  const getClassName = val => {
    const score = Number(val) * 10
    if (score < 1) {
      return ''
    }
    return `n${score}`
  }
  const fnUpdateCommentRecommend = async item => {
    if (proxy.$Util.isEmpty(state.userData)) {
      alert('로그인이 필요합니다.')
      item.recommendMine = false
      return false
    }
    const params = {}
    params.seq = item.seq
    const res = await proxy.$WizzpediaSvc.postWizzpediaUpdateCommentRecommend(
      params
    )
    if (res.resultCode !== '0000') {
      alert(res.resultMsg)
    } else {
      if (item.recommendMine) item.recommendCount--
      else item.recommendCount++
      item.recommendMine = !item.recommendMine
    }
  }
  const fnView = async item => {
    // https://localhost:8081/community/wizzpedia/view?id=65898&category=netflix
    await router.push({
      path: '/community/wizzpedia/view',
      query: {
        id: item.id,
        category: item.category,
        commentPage: item.commentPage
      }
    })
  }
  return {
    getClassName,
    fnUpdateCommentRecommend,
    fnView,
    ...toRefs(state)
  }
}
