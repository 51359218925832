<template>
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="lump">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해주세요."
                v-model="searchText"
                @keyup.enter="fnSearch"
              />
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <!--                            210713삭제-->
                <!--                            <label class="checkbox">-->
                <!--                                <input type="checkbox">-->
                <!--                                <span class="label"><span>종료 비노출</span></span>-->
                <!--                            </label>-->
                <label class="select">
                  <select>
                    <option>업데이트순</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="party_list_wrap">
              <party-score-list :list="list" />
              <observe-visible
                :key="initCnt"
                :page="page"
                :limit="limit"
                :totalCnt="totalCnt"
                @fetch="fnListFunction"
                v-show="isShow"
                v-if="isInit"
              />
              <!--                        210713수정끝-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import PartyScoreList from '@/components/views/party/score-list/index.vue'
import { componentState } from './index.js'

export default {
  name: 'index',
  components: { PartyScoreList },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
