import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const INIT_PAGE = 0
const INIT_LIMIT = 6
// const INIT_TOTAL_CNT = 999

export const componentState = () => {
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    initCnt: 0,
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: 0,
    isInit: false,
    isShow: false,
    searchText: '',
    params: computed({
      get: () => {
        return {
          page: state.page + 1,
          searchText: state.searchText
        }
      }
    }),
    list: []
  })

  const fnListFunction = async () => {
    state.isShow = false
    const res = await proxy.$WizzpediaSvc.postGetTotalCommentList(state.params)
    state.totalCnt = res.totalCnt
    if (res.resultCode === '0000') {
      state.page++
      state.list = state.list.concat(
        res.list.map(item => {
          return {
            ...item
          }
        })
      )
      state.isShow = true
    } else {
      state.isShow = false
    }
  }

  const fnSearch = async () => {
    // state.page = INIT_PAGE
    // state.limit = INIT_LIMIT
    // state.totalCnt = INIT_TOTAL_CNT
    // state.list = []
    // state.initCnt++
    // await fnListFunction()
    await router.push({
      path: '/community/party-score/search',
      query: { searchText: state.searchText }
    })
  }

  const init = async () => {
    await fnListFunction()
    state.isInit = true
    state.isShow = true
  }
  init()
  return { ...toRefs(state), fnListFunction, fnSearch }
}
